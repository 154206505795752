import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent {
  public form: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.pattern(/^(?!.*[-'\s.]{2})(?![-'\s.])[A-Za-z][-'\s.A-Za-z]{1,39}$/)]),
    lastName: new FormControl('', [Validators.required, Validators.pattern(/^(?!.*[-'\s.]{2})(?![-'\s.])[A-Za-z][-'\s.A-Za-z]{1,39}$/)]),
    phone: new FormControl('', [Validators.required, Validators.minLength(10),
      Validators.maxLength(17)]),
    email: new FormControl('', [Validators.required, Validators.pattern(  /(?=^.{1,320}$)(?=^\S+@\S{1,255}$)(^[A-Za-z0-9!#$%&’;+\-.=?^_`{}½~]{1,64}@[A-Za-z0-9.\-]+\.[A-Za-z0-9.\-]+[A-Za-z0-9]+$)/)]),
  });

  isFieldInvalid(fieldName: string) {
    return this.form.controls[fieldName].touched &&
      !this.form.controls[fieldName].valid;
  }
}
