<header class="header">
  <div class="container">
    <img src="assets/img/logo.svg" alt="" class="logo">
  </div>
</header>
<div class="container">
  <div class="inner">
    <img src="assets/img/icon-app-store.svg" alt="icon app store" class="staff">
    <div class="content">
      <div class="mobile">
        <img src="assets/img/icon-app-store.svg" alt="icon app store" class="staff-mobile">
        <h1 class="title">
          Personal Data Erasure
        </h1>
      </div>
      <h5 class="subtitle">
        If you are a user of the Casheers Staff application, you can delete your personal data
      </h5>
      <div class="points">
        <div class="point">
          <p class="number" style="  padding: 3px 16px;">1</p>
          <p class="point__text">Enter your personal information in the appropriate fields below. You must fill out all
            4 fields</p>
        </div>
        <div class="point">
          <p class="number">2</p>
          <p class="point__text">Click on the Submit button</p>
        </div>
        <div class="point">
          <p class="number">3</p>
          <p class="point__text">Wait for an email notification that your personal data has been deleted</p>
        </div>
      </div>
      <form action="" class="form" [formGroup]="form">
        <div class="item" [ngClass]="{'field-error': isFieldInvalid('firstName')}">
          <label class="label">
            First name*
          </label>
          <input type="text" class="input" formControlName="firstName">
          <p class="error" *ngIf="isFieldInvalid('firstName')">Please enter a valid first name</p>
        </div>
        <div class="item" [ngClass]="{'field-error': isFieldInvalid('lastName')}">
          <label class="label">
            Last name*
          </label>
          <input type="text" class="input" formControlName="lastName">
          <p class="error" *ngIf="isFieldInvalid('lastName')">Please enter a valid last name</p>
        </div>
        <div class="item" [ngClass]="{'field-error': isFieldInvalid('email')}">
          <label class="label">
            Email*
          </label>
          <input type="text" class="input" formControlName="email">
          <p class="error" *ngIf="isFieldInvalid('email')">Please enter a valid email</p>
        </div>
        <div class="item" [ngClass]="{'field-error': isFieldInvalid('phone')}">
          <label class="label">
            Phone number*
          </label>
          <input type="text" class="input" formControlName="phone" phoneNumber>
          <p class="error" *ngIf="isFieldInvalid('phone')">Please enter a valid phone number</p>
        </div>
        <button routerLink="success" class="btn" [disabled]="form.invalid">
          Submit
        </button>
      </form>
    </div>
  </div>
</div>
