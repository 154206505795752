import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainPageComponent} from './components/main-page/main-page.component';
import {SuccessComponent} from './components/success/success.component';
import {ReactiveFormsModule} from '@angular/forms';
import { RegisterDirective } from './components/main-page/register.directive';

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    SuccessComponent,
    RegisterDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
