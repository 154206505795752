<header class="header">
  <div class="container">
    <img src="assets/img/logo.svg" alt="" class="logo">
  </div>
</header>
<div class="container">
  <div class="inner">
    <img src="assets/img/icon-app-store.svg" alt="icon app store" class="staff">
    <div class="content">
      <div class="mobile">
        <img src="assets/img/icon-app-store.svg" alt="icon app store" class="staff-mobile">
        <h1 class="title">
          Congratulations!
        </h1>
      </div>
      <h5 class="subtitle">
        The request has been successfully sent and will be processed within 14 days.
      </h5>
      <h5 class="subtitle">
        After erasion of your personal data, you will receive a notification by mail
      </h5>
    </div>
  </div>
</div>
