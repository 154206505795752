import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[phoneNumber]',
})
export class RegisterDirective {
    constructor(private phoneRef: ElementRef) {
    }

    @HostListener('input', ['$event'])
    onInput(): void {
        this.phoneRef.nativeElement.value = this.getPhoneNumber(this.phoneRef.nativeElement);
    }

    // eslint-disable-next-line class-methods-use-this
    getPhoneNumber(nativeElement: any) {
        let inputValue = nativeElement.value;
        if (inputValue == null || inputValue.length < 1) return '';

        if (inputValue[0] === '0') {
            const arr = inputValue.split('');
            arr.splice(0, 1, '66');
            inputValue = arr.join('');
        }

        const reg = /\D/g;
        const inputNumbersValue = inputValue.replace(reg, '');
        if (!inputNumbersValue) {
            return '';
        }

        return `+${inputNumbersValue.substring(0, 16)}`;
    }
}
